<template>
  <div class="h-10 absolute left-0 w-full float-left">
    <div
      :style="`width: ${getWidth(
        event,
        'proposed'
      )}%; margin-left: ${getLeftMargin(event, 'proposed', index)}%;`"
      v-for="(event, index) in proposeEvents"
      :key="event.id + 'proposed'"
      class="bg-green-500 border h-5 rounded"
      :data-range="
        JSON.stringify({
          proposed_grabbed_at: event.proposed_grabbed_at,
          proposed_returned_at: event.proposed_returned_at,
          grabbed_at: event.grabbed_at,
          returned_at: event.returned_at
        })
      "
    >
      <!-- {{ event.proposed_grabbed_at }} to
      {{ event.proposed_returned_at }} -->
    </div>
    <div
      :style="`width: ${getWidth(
        event,
        'actual'
      )}%; margin-left: ${getLeftMargin(event, 'actual', index)}%;`"
      v-for="(event, index) in actualEvents"
      :key="event.id + 'actual'"
      class="border h-5 rounded"
      :class="{
        'bg-rose-500': event.returned_at != null,
        'bg-yellow-500': event.returned_at == null
      }"
      :data-range="
        JSON.stringify({
          proposed_grabbed_at: event.proposed_grabbed_at,
          proposed_returned_at: event.proposed_returned_at,
          grabbed_at: event.grabbed_at,
          returned_at: event.returned_at
        })
      "
    >
      <!-- {{ event.grabbed_at }} to
      {{ event.returned_at }} -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import { groupByDate } from "../helpers";
export default {
  name: "MaxtrixTick",
  props: {
    events: {
      type: Array,
      default: () => []
    },
    fromDate: {
      type: Object,
      required: true
    },
    toDate: {
      type: Object,
      required: true
    }
  },
  computed: {
    secInWeek() {
      return this.toDateUnix - this.fromDateUnix;
    },
    fromDateUnix() {
      return moment(this.fromDate).unix();
    },
    toDateUnix() {
      return moment(this.toDate).endOf("day").unix();
    },
    actualEvents() {
      return this.events
        .concat()
        .sort((a, b) => {
          return new Date(a.grabbed_at) - new Date(b.grabbed_at);
        })
        .filter(event => {
          if (
            moment(event.grabbed_at).unix() > this.fromDateUnix &&
            moment(event.grabbed_at).unix() < this.toDateUnix
          ) {
            return true;
          }
          if (
            moment(event.returned_at).unix() > this.fromDateUnix &&
            moment(event.returned_at).unix() < this.toDateUnix
          ) {
            return true;
          }
          if (
            moment(event.returned_at).unix() > this.fromDateUnix &&
            moment(event.grabbed_at).unix() < this.fromDateUnix
          ) {
            return true;
          }
          if (
            moment(event.returned_at).unix() > this.toDateUnix &&
            moment(event.grabbed_at).unix() < this.toDateUnix
          ) {
            return true;
          }
          if (
            event.returned_at == null &&
            moment(event.grabbed_at).unix() < this.toDateUnix
          ) {
            return true;
          }
          return false;
        });
    },
    proposeEvents() {
      return this.events
        .concat()
        .sort((a, b) => {
          return (
            new Date(a.proposed_grabbed_at) - new Date(b.proposed_grabbed_at)
          );
        })
        .filter(event => {
          if (
            moment(event.proposed_grabbed_at).unix() > this.fromDateUnix &&
            moment(event.proposed_grabbed_at).unix() < this.toDateUnix
          ) {
            return true;
          }
          if (
            moment(event.proposed_returned_at).unix() > this.fromDateUnix &&
            moment(event.proposed_returned_at).unix() < this.toDateUnix
          ) {
            return true;
          }
          if (
            moment(event.proposed_returned_at).unix() > this.fromDateUnix &&
            moment(event.proposed_grabbed_at).unix() < this.fromDateUnix
          ) {
            return true;
          }
          if (
            moment(event.proposed_returned_at).unix() > this.toDateUnix &&
            moment(event.proposed_grabbed_at).unix() < this.toDateUnix
          ) {
            return true;
          }
          if (
            event.proposed_returned_at == null &&
            moment(event.proposed_grabbed_at).unix() < this.toDateUnix
          ) {
            return true;
          }
          return false;
        });
    }
  },
  methods: {
    getLeftMargin(event, type, index) {
      // the space between the last tick thats why needs index
      let grabbed_at =
        type == "proposed" ? "proposed_grabbed_at" : "grabbed_at";
      let returned_at =
        type == "proposed" ? "proposed_returned_at" : "returned_at";
      if (index == 0) {
        const left =
          ((moment(event[grabbed_at]).unix() - this.fromDateUnix) * 100) /
          this.secInWeek;
        return left > 0 ? left : 0;
      }

      let returned_at_unix = moment(event[returned_at]).unix();
      if (event[returned_at] == null) {
        returned_at_unix = moment().unix();
      }

      const left =
        ((moment(event[grabbed_at]).unix() - returned_at_unix) * 100) / // - moment().unix();
        this.secInWeek;
      return left > 0 ? left : 0;
    },
    getWidth(event, type) {
      let grabbed_at =
        type == "proposed" ? "proposed_grabbed_at" : "grabbed_at";
      let returned_at =
        type == "proposed" ? "proposed_returned_at" : "returned_at";

      // const grabOrStart = moment(event.proposed_grabbed_at).unix()
      let first =
        this.fromDateUnix > moment(event[grabbed_at]).unix()
          ? this.fromDateUnix
          : moment(event[grabbed_at]).unix();

      let returned_at_unix = moment(event[returned_at]).unix();
      if (event[returned_at] == null) {
        returned_at_unix = moment().unix();
      }

      let second =
        this.toDateUnix < returned_at_unix ? this.toDateUnix : returned_at_unix;
      const diff = second - first;
      if (diff <= 0) {
        return 0;
      }
      const width = (diff * 100) / this.secInWeek;
      return width > 100 ? 100 : width;
    },
    getUnixTime(dt) {
      return moment(dt).unix();
    }
  }
};
</script>
