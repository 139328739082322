var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-10 absolute left-0 w-full float-left"},[_vm._l((_vm.proposeEvents),function(event,index){return _c('div',{key:event.id + 'proposed',staticClass:"bg-green-500 border h-5 rounded",style:(("width: " + (_vm.getWidth(
      event,
      'proposed'
    )) + "%; margin-left: " + (_vm.getLeftMargin(event, 'proposed', index)) + "%;")),attrs:{"data-range":JSON.stringify({
        proposed_grabbed_at: event.proposed_grabbed_at,
        proposed_returned_at: event.proposed_returned_at,
        grabbed_at: event.grabbed_at,
        returned_at: event.returned_at
      })}})}),_vm._l((_vm.actualEvents),function(event,index){return _c('div',{key:event.id + 'actual',staticClass:"border h-5 rounded",class:{
      'bg-rose-500': event.returned_at != null,
      'bg-yellow-500': event.returned_at == null
    },style:(("width: " + (_vm.getWidth(
      event,
      'actual'
    )) + "%; margin-left: " + (_vm.getLeftMargin(event, 'actual', index)) + "%;")),attrs:{"data-range":JSON.stringify({
        proposed_grabbed_at: event.proposed_grabbed_at,
        proposed_returned_at: event.proposed_returned_at,
        grabbed_at: event.grabbed_at,
        returned_at: event.returned_at
      })}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }