<template>
  <div class="md:-mt-12">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12 z-0" />
    <div class="pt-10 px-8 md:px-14">
      <div class="rounded-t bg-white mb-0 px-6 py-6 relative z-10">
        <div class="text-center flex justify-between items-center">
          <div class="flex items-center">
            <h6 class="text-blueGray-500 text-xl font-bold md:mr-10">
              {{ $t("Booking Matrix") }}
            </h6>
            <div
              class="mr-2 mx-1 px-2 border rounded cursor-pointer font-semibold"
              :class="{
                'bg-gray-700 text-gray-50': matrixView == selectedMatrixView
              }"
              @click="setMatrixView(matrixView)"
              v-for="matrixView in matrixViews"
              :key="matrixView"
            >
              {{ matrixView }}
            </div>
          </div>

          <div class="flex items-center">
            <i
              class="fas fa-chevron-left mx-3 cursor-pointer"
              @click="prevStartEnd"
            />
            <div class="font-semibold">
              <div class="font-bold px-4 text-gray-500 text-sm">
                <span>{{ getDateOnly(fromDate) }}</span>
                <i class="fas fa-minus text-xs mx-2" />
                <span>{{ getDateOnly(toDate) }}</span>
                <span
                  v-if="selectedMatrixView != 'month'"
                  class="ml-2 text-blue-640"
                >
                  (#{{ currentWeek }})
                </span>
              </div>
            </div>
            <i
              class="fas fa-chevron-right mx-3 cursor-pointer"
              @click="nextStartEnd"
            />
          </div>
          <!-- <div class="flex items-center" v-if="selectedMatrixView == 'month'">
            <i
              class="fas fa-chevron-left mx-3 cursor-pointer"
              @click="prevMonth"
            />
            <div class="font-semibold">
              <div class="font-bold px-4 text-gray-500 text-sm">
                <span>{{ getDateOnly(startOfMonth) }}</span>
                <i class="fas fa-minus text-xs mx-2" />
                <span>{{ getDateOnly(endOfMonth) }}</span>
              </div>
            </div>
            <i
              class="fas fa-chevron-right mx-3 cursor-pointer"
              @click="nextMonth"
            />
          </div> -->
        </div>
      </div>
      <div
        style="height: calc(100vh - 255px)"
        class="relative flex flex-col min-w-0 break-words w-full mb-6 mx-auto shadow-lg rounded-lg rounded-t-none bg-blueGray-100 border-0"
      >
        <div class="flex">
          <div
            class="w-64 head flex justify-between py-3 px-2 items-center font-semibold text-center"
          >
            {{ $t("asset") }}
          </div>
          <div
            class="flex h-full absolute ml-64 overflow-auto top-0"
            style="width: calc(100% - 16rem)"
          >
            <div
              v-for="(day, i) in periodRange"
              :key="selectedMatrixView != 'day' ? day.format() : day"
              tag="div"
              :style="`width: calc(100% / ${periodRange.length})`"
              class="disable-marker pt-0 clickable sortable-animation bg-blueGray-100 border-l"
            >
              <div
                ref="dayCols"
                :key="`${i}-header`"
                class="head text-sm sticky top-0 bg-blueGray-100"
                :class="{
                  'flex items-center justify-center':
                    selectedMatrixView != 'month'
                }"
              >
                <div
                  class="font-semibold text-gray-640"
                  :class="{
                    'pt-3 flex items-center': selectedMatrixView != 'month'
                  }"
                >
                  <div class="text-center" v-if="selectedMatrixView == 'day'">
                    {{ day }}
                  </div>
                  <div class="text-center" v-if="selectedMatrixView == 'week'">
                    {{ getDateNoYearOnly(day) }}
                  </div>
                  <div class="text-center" v-if="selectedMatrixView == 'month'">
                    {{ getDayOnly(day) }}
                  </div>
                  <div
                    class="font-semibold text-blue-500 text-xs text-center px-1"
                    v-if="selectedMatrixView != 'day'"
                  >
                    [{{ getWeekDayAbbrev(day) }}]
                  </div>
                  <div
                    class="bg-green-500 rounded px-1 text-green-50 text-xs mx-1"
                    v-if="today.isSame(day) && selectedMatrixView == 'week'"
                  >
                    {{ $t("today") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="overflow-scroll border-t z-50"
          style="height: calc(100vh - 300px)"
        >
          <div class="left-64 absolute" style="width: calc(100% - 16rem)">
            <div
              class="border-r h-100 border-orange-500 z-50 absolute"
              :style="`margin-left: ${nowPosition}%; height: calc(100vh - 300px)`"
            />
          </div>
          <!-- {{ nowPosition }} adlfjasdlja;sjfasf -->
          <div
            class="flex"
            v-for="(bike, index) in $store.state.bikes.bikes"
            :key="bike.id"
            :class="{
              'bg-white': index % 2 == 0
            }"
          >
            <div class="w-64 py-3 px-3 border-b">
              {{ bike.name }} ({{ bike.id }})
            </div>
            <div
              class="flex border-b z-10 relative"
              style="width: calc(100% - 16rem)"
            >
              <MaxtrixTick
                :events="bike.checkouts"
                :fromDate="fromDate"
                :toDate="toDate"
              />
              <!-- <div
                class="border-r h-100 border-orange-500 z-50"
                :style="`margin-left: ${nowPosition}%`"
              /> -->
              <div
                v-for="(day, i) in periodRange"
                class="border-l"
                :key="index + `gantt ${i}`"
                tag="div"
                :style="`width: calc(100% / ${periodRange.length})`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex px-16">
      <div class="mr-2 flex items-center font-semibold">
        <div class="bg-rose-500 h-5 w-5 mr-1 rounded" />
        {{ $t("Done") }}
      </div>
      <div class="mr-2 flex items-center font-semibold">
        <div class="bg-yellow-500 h-5 w-5 mr-1 rounded" />
        {{ $t("In Progress") }}
      </div>
      <div class="mr-2 flex items-center font-semibold">
        <div class="bg-green-500 h-5 w-5 mr-1 rounded" />
        {{ $t("Scheudled") }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import formatting from "../mixins/formatting";
import MaxtrixTick from "../components/MaxtrixTick.vue";
export default {
  name: "BookingMatrix",
  components: { MaxtrixTick },
  mixins: [formatting],
  data() {
    const today = moment().startOf("day");
    const selectedMatrixView = "week";
    const { fromDate, toDate, currentWeek } = this.getStartEnd(
      today,
      selectedMatrixView
    );

    return {
      matrixViews: ["day", "week", "month"],
      selectedMatrixView,
      day: today,
      today,
      currentWeek,
      fromDate,
      toDate,
      cardWidth: null
    };
  },
  computed: {
    nowPosition() {
      let fromDateUnix = moment(this.fromDate).unix();
      let toDateUnix = moment(this.toDate).endOf("day").unix();
      let totalSeconds = toDateUnix - fromDateUnix;
      let now = moment().unix();
      return ((now - fromDateUnix) * 100) / totalSeconds;
    },
    periodRange() {
      if (this.selectedMatrixView == "day") {
        return [...Array(24).keys()];
      }
      const diffDays = this.toDate.diff(this.fromDate, "days");
      return [...Array(diffDays + 1).keys()].map(i =>
        moment(this.fromDate).add(i, "days")
      );
    }
  },
  methods: {
    setMatrixView(matrixView) {
      this.selectedMatrixView = matrixView;
      this.setStartEnd();
    },
    setStartEnd() {
      const { fromDate, toDate, currentWeek } = this.getStartEnd(
        this.day,
        this.selectedMatrixView
      );
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.currentWeek = currentWeek;
    },
    prevStartEnd() {
      if (this.selectedMatrixView == "week") {
        this.day = moment(this.fromDate).subtract(1, "days");
      } else if (this.selectedMatrixView == "month") {
        this.day = moment(this.fromDate).subtract(1, "days");
      } else if (this.selectedMatrixView == "day") {
        this.day = moment(this.day).subtract(1, "days");
      }
      this.setStartEnd();
    },
    nextStartEnd() {
      if (this.selectedMatrixView == "week") {
        this.day = moment(this.toDate).add(1, "days");
      } else if (this.selectedMatrixView == "month") {
        this.day = moment(this.toDate).add(1, "days");
        // this.getStartEnd(day, this.selectedMatrixView);
      } else if (this.selectedMatrixView == "day") {
        this.day = moment(this.day).add(1, "days");
      }
      this.setStartEnd();
    },
    getStartEnd(d, maxtrixView) {
      let fromDate = moment(d).startOf("isoWeek");
      let toDate = moment(d).endOf("isoWeek").startOf("day");
      let currentWeek = moment(d).isoWeek();
      if (maxtrixView == "month") {
        fromDate = moment(d).startOf("month");
        toDate = moment(d).endOf("month");
      } else if (maxtrixView == "day") {
        fromDate = moment(d).startOf("day");
        toDate = moment(d).endOf("day");
      }
      return {
        fromDate,
        toDate,
        currentWeek
      };
    }
  },
  created() {
    this.$store.dispatch("bikes/getAllBookableAssets");
  }
};
</script>
